import getUser from '../user/getUser'

const setSubscribed = () => {
  const notSubscribedElement = document.querySelectorAll('.not-subscribed')
  const subscribedElement = document.querySelectorAll('.subscribed')
  const userData = getUser()

  let isSubscribed = false

  if (userData) {
    const { user = {} } = userData
    const { user_metadata } = user

    if (user_metadata.subscribed) {
      isSubscribed = true
    }
  }

  if (!isSubscribed) {
    notSubscribedElement.forEach((element) => {
      element.classList.remove('hidden')
    })

    subscribedElement.forEach((element) => {
      element.classList.add('hidden')
    })
  } else {
    notSubscribedElement.forEach((element) => {
      element.classList.add('hidden')
    })

    subscribedElement.forEach((element) => {
      element.classList.remove('hidden')
    })
  }
}

export default setSubscribed
