import validator from 'validator'

const isValidEmail = (email, error) => {
  // Check if email is valid
  if (!validator.isEmail(email)) {
    const errorElement = error

    errorElement.innerHTML = 'Please use a valid email address.'
    errorElement.classList.remove('hidden')

    return false
  }

  return true
}

export default isValidEmail
